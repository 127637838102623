<template>
  <router-view v-if="web_app.platform !== 'unknown' && is_there_key"/>
  <div v-else-if="!is_there_key" class="min-h-screen flex justify-center items-center">
	<div class="text-2xl text-center"><el-icon class="animate-spin"><RefreshRight /></el-icon></div>
  </div>
  <div v-else class="min-h-screen flex justify-center items-center">
	<el-empty description="Botga /start buyrug'ini yuboring va qayta urinib ko'ring." />
  </div>
</template>

<script>
	import axios from 'axios'
	import store from '@/store'

	export default {
		data() {
			return {
				web_app: window.Telegram.WebApp,
				is_there_key: false,
			}
		},
		mounted(){
      this.web_app.expand();
			document.getElementsByTagName('html')[0].classList.add(this.web_app.colorScheme);
			if(this.web_app.platform !== 'unknown'){
				this.$router.isReady().then(() => {
					if(this.$route.query.key){
						this.$cookies.set('key', this.$route.query.key);
						this.is_there_key = true;
					}
					if(this.$cookies.get('key')){
						axios.defaults.headers["Authorization"] = `Token ${this.$cookies.get('key')}`;
						this.is_there_key = true;
						this.loadUserInfo();
					}
				})
			}
		},
		methods: {
			async loadUserInfo(){
        await axios.get(`users/me/`).then((response) => {
					store.commit('setUserInfo', response.data);
        }).catch((error) => {
          console.log(error);
        })
      },
		}
	}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

* {
	font-family: 'Roboto Condensed', sans-serif;
	outline: none;
	-webkit-tap-highlight-color: transparent;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
