import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/parishes',
    name: 'parishes',
    component: () => import('@/pages/parishes/index.vue')
  },
  {
    path: '/fragmentations',
    name: 'fragmentations',
    component: () => import('@/pages/fragmentations/index.vue')
  },
  {
    path: '/allocations',
    name: 'allocations',
    component: () => import('@/pages/allocations/index.vue')
  },
  {
    path: '/leftovers',
    name: 'leftovers',
    component: () => import('@/pages/leftovers/index.vue')
  },
  {
    path: '/expenses',
    name: 'expenses',
    component: () => import('@/pages/expenses/index.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
